import React from 'react'

import {
  Box,
  ButtonGroup,
  ProgressIndicator,
  P,
  H2,
  H1,
} from '@allied-solutions/affinity'

import {
  useAddFormValuesToDataLayer,
  useRedirectToEnterRefId,
  useStore,
  useSubmitInsuranceLogic,
} from '../../../hooks'
import {
  AppLayout,
  BackButton,
  FileUploader,
  InfoBox,
  NextButton,
  StepNumber,
  SubmitInsuranceLayout,
} from '../../../components'

const UploadDocuments = ({ location }) => {
  useRedirectToEnterRefId()
  const { dispatch, state } = useStore()
  const { pages, proceed } = useSubmitInsuranceLogic(location)
  const addFormValuesToDataLayer = useAddFormValuesToDataLayer()

  const handleSubmit = e => {
    // console.log(state.form.files)
    e.preventDefault()
    addFormValuesToDataLayer()
    proceed()
  }

  return (
    <SubmitInsuranceLayout>
      <form onSubmit={handleSubmit}>
        <AppLayout
          leftSide={
            <>
              <StepNumber pages={pages} location={location} />
              <H1 typeStyle="headlineMedium" fontWeight={900}>
                Upload your insurance documents (Optional).
              </H1>
              <P typeStyle="bodyMedium" textAlign="left" color="gray600">
                If you have a copy of your insurance policy documents, you can
                upload them here.
              </P>
              <P
                typeStyle="bodyMedium"
                textAlign="left"
                color="gray600"
                mb={14}
              >
                This step is optional but will speed up the verification
                process.
              </P>
            </>
          }
          rightSide={
            <>
              <InfoBox>
                <Box
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                  pb="6"
                >
                  <H2
                    className="titleSmall"
                    typeStyle="titleSmall"
                    fontWeight={900}
                  >
                    Upload Documents
                  </H2>
                  <P typeStyle="bodySmall" textAlign="left" color="gray600">
                    Optional
                  </P>
                </Box>

                <FileUploader
                  files={state.form?.files || []}
                  onChange={files => {
                    dispatch({
                      type: 'addToState',
                      payload: { form: { ...state.form, files: files } },
                    })
                  }}
                >
                  <FileUploader.Control
                    id="fileUpload"
                    accept="application/pdf, image/jpeg, image/tiff"
                    multiple
                  />
                  {!!state?.form?.files?.length && (
                    <Box>
                      <P
                        typeStyle="labelMedium"
                        textAlign="left"
                        mb={4}
                        mt={7}
                        display="block"
                      >
                        Selected files
                      </P>
                      <FileUploader.FilesList />
                    </Box>
                  )}
                </FileUploader>
              </InfoBox>
            </>
          }
          footerButtons={
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
            >
              <ProgressIndicator
                pages={pages}
                currentPage={location.pathname}
              />
              <ButtonGroup display="flex" justifyContent="flex-end">
                <BackButton />
                <NextButton
                  // spoof formik props
                  formikProps={{ isSubmitting: false, isValid: true }}
                />
              </ButtonGroup>
            </Box>
          }
        />
      </form>
    </SubmitInsuranceLayout>
  )
}

export default UploadDocuments
